<!--  -->
<template>
	<div class="erp">
		<div class="swiper">
			<div class="swiper-wrapper">
				<div class="swiper-slide">
					<div class="content-box">
						<div>
							<img class="phone-dn" src="@/assets/pro/img_fonts.png" alt="" />
                            <img
								style="padding-top: 50%"
								class="phone-db"
								src="@/assets/h5-pro/fonts.png"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
        <div class="phone-dn frid-box">
            <div class="frid-box-bg"></div>
            <!-- <img class="phone-dn" src="@/assets/pro/frid.png" alt="" /> -->
        </div>
        <div class="frid-box-h5 phone-db">
            <img  src="@/assets/h5-pro/wms_tl.png" style="width:100%" alt="" />
        </div>
		<van-overlay :show="show">
			<div class="commons-overlay-warp" @click.stop>
				<div class="title">
					申请试用系统
					<i class="iconfont" @click="show = false">&#xe6b3;</i>
				</div>
				<div class="label">具体申请方法</div>
				<div>
					1.咨询热线：<span style="color: #487cff"
						>0591-87865886</span
					>
				</div>
				<div>2.扫描咨询：</div>
				<div class="ewm">
					<div class="ewm-box">
						<div class="ewm-warp">
							<img src="@/assets/images/img_ewm1.png" alt="" />
						</div>
						<p>企业微信客服</p>
					</div>
					<div class="ewm-box">
						<div class="ewm-warp">
							<img src="@/assets/images/img_gzhewm.png" alt="" />
						</div>
						<p>关注企业公众号</p>
					</div>
				</div>
			</div>
		</van-overlay>
		<div class="painPoint" style="padding-top:100px">
			<div class="commons-title" :class="advantageAnimate">
				仓储管理的<span>痛点</span>和<span>疑问</span>
			</div>
			<div class="commons-title-label" :class="advantageAnimate">
				传统仓储系统管理中，收集出当前存在的问题
			</div>
            <div class="phone-db" style="padding:40px 27px  100px" :class="advantageAnimate">
				<img
					style="width: 100%;"
					src="@/assets/h5-pro/img_wh.png"
					alt=""
				/>
                <img
					style="width: 100%;margin-top: 20px;"
					src="@/assets/h5-pro/img_wh_2.png"
					alt=""
				/>
			</div>
			<div class="content phone-dn" :class="advantageAnimate">
				<img  style="display: block;margin: 0 auto;" src="@/assets/pro/banner-2.png" alt="" />
			</div>
		</div>
		<div class="zhendui">
            <div class="zhendui-content">
                <div class="zhendui-logo">
                    <img src="../assets/pro/zhendui-logo.png" alt="">
                </div>
                <div class="commons-title"  :class="programmeAnimete">
                    <span>传统</span>与<span>现在</span>仓储管理的差异化
                </div>
                <div class="commons-title-label"  :class="programmeAnimete">
                    根据目前的发展，列出以下仓储管理不同方面
                </div>
                <div class="phone-dn" :class="programmeAnimete">
                    <img  style="display: block;margin: 40px auto 0;" src="@/assets/pro/banner-1.png" alt="" />
                </div>
                <div class="phone-db" :class="programmeAnimete">
                    <img
                        style="width: 100%;margin-top: 40px;"
                        src="@/assets/h5-pro/banner-1.png"
                        alt=""
                    />
                </div>
            </div>
        </div>
        <div class="advantage" style="padding-top:40px">
            <div class="advantage-content">
                <div class="commons-title"  :class="coreAnimate1">
                    我们<span>的优势</span>
                </div>
                <div class="commons-title-label"  :class="coreAnimate1">
                    通过对库存物料的精益管理提高企业资金占比最高的库存物资投资回报率
                </div>
                <div class="content phone-dn" :class="coreAnimate1">
                    <img style="display: block;margin: 0 auto;" src="@/assets/pro/banner-3.png" alt="" />
                </div>
                <div class="phone-db" :class="coreAnimate1">
                    <img style="width:100%" src="@/assets/h5-pro/img_ys4.png" alt="" />
                    <img style="width:100%;margin-top:40px" src="@/assets/h5-pro/banner-3.png" alt="" />
                </div>
            </div>
        </div>
        <div class="advantage">
            <div class="advantage-content">
                <div class="commons-title"  :class="coreAnimate2">
                    云仓进销存系统<span>解决方案</span>
                </div>
                <div class="commons-title-label"  :class="coreAnimate2">
                    云仓系统也在不断的研发新功能，确保客户享有一站式仓储管理便利性
                </div>
            </div>
        </div>
        <div class="customer" >
            <div class="customer-content">
                <div class="img phone-dn" :class="coreAnimate3">
                    <img src="../assets/pro/img_gyxy.png" alt="">
                </div>
                <div class="text" :class="coreAnimate3">
                    <div>
                        <img src="../assets/pro/icon_gyxy.png" alt="">
                        <h3>供应协议</h3>
                    </div>
                    <p>通过对合作企业的授信管理，再到签署周期性协议，最后再到确认签署的合同盖章一系列流程通过供应链系统简单明了各类型的供应协议关系。</p>
                    <img class="phone-db" src="../assets/pro/img_gyxy.png" alt=""/>
                </div>
            </div>
        </div>
        <div class="black-warp" >
            <div class="black-content">
                <div class="text" :class="coreAnimate4">
                    <div>
                        <img src="../assets/pro/icon_jcfz.png" alt="">
                        <h3>决策辅助</h3>
                    </div>
                    <p>在目前的OA采购审批的过程中，申请人和决策人都浪费大量时间查阅和审核真实情况再进行提报和审核。<br> 采购员根据数据分析引擎决策是否采购<br>  管理员根据数据辅助分析引擎审核的各个条件 <br> 从而实现了机器人+人的人机结合高效决策</p>
                </div>
                <div class="img" :class="coreAnimate4">
                    <img class="phone-dn" src="../assets/pro/img_jcfz.png" alt="">
                    <img class="phone-db" style="width:100%" src="../assets/h5-pro/img_jcfz.png" alt="">
                </div>
            </div>
        </div>
        <div class="customer" >
            <div class="customer-content">
                <div class="img phone-dn" :class="coreAnimate5">
                    <img src="../assets/pro/img_fkgl.png" alt="">
                </div>
                <div class="text" :class="coreAnimate5">
                    <div>
                        <img src="../assets/pro/icon_fkgl.png" alt="">
                        <h3>付款管理</h3>
                    </div>
                    <p>通过对合作企业的授信管理，再到签署周期性协议，最后再到确认签署的合同盖章一系列流程通过供应链系统简单明了各类型的供应协议关系。</p>
                    <img class="phone-db" style="width:100%" src="../assets/h5-pro/img_fkgl.png" alt="">
                </div>
            </div>
        </div>
        <div class="black-warp">
            <div class="black-content">
                <div class="text"  :class="coreAnimate6">
                    <div>
                        <img src="../assets/pro/icon_kwgl.png" alt="">
                        <h3>库位管理+先入先出</h3>
                    </div>
                    <p>在目前的OA采购审批的过程中，申请人和决策人都浪费大量时间查阅和审核真实情况再进行提报和审核。<br> 采购员根据数据分析引擎决策是否采购<br> 管理员根据数据辅助分析引擎审核的各个条件<br> 从而实现了机器人+人的人机结合高效决策</p>
                </div>
                <div class="img"  :class="coreAnimate6">
                    <img class="phone-dn" src="../assets/pro/img_kwgl.png" alt="">
                    <img class="phone-db" style="width:100%"  src="../assets/h5-pro/img_kwgl.png" alt="">
                </div>
            </div>
        </div>
        <div class="customer">
            <div class="customer-content">
                <div class="img phone-dn" :class="coreAnimate7">
                    <img src="../assets/pro/img_sjkb.png" alt="">
                </div>
                <div class="text" :class="coreAnimate7">
                    <div>
                        <img src="../assets/pro/icon_sjkb.png" alt="">
                        <h3>数据看板</h3>
                    </div>
                    <p>通过对合作企业的授信管理，再到签署周期性协议，最后再到确认签署的合同盖章一系列流程通过供应链系统简单明了各类型的供应协议关系。</p>
                    <img class="phone-db" style="width:100%" src="../assets/h5-pro/img_sjkb.png" alt="">
                </div>
            </div>
        </div>
        <div class="black-warp">
            <div class="black-content">
                <div class="text"  :class="coreAnimate8">
                    <div>
                        <img src="../assets/pro/icon_ztkc.png" alt="">
                        <h3>整体库存、各物料明细一目了然</h3>
                    </div>
                    <p>结合移动互联网的功能、可以实时共享查询和汇总报表，无需要仓库管理者运算，数据实时共享给相关领导，比如时间段查询、多仓库和各个仓库的情况，包括人员领用的记录。</p>
                    
                </div>
                <div class="img"  :class="coreAnimate8">
                    <img class="phone-dn" src="../assets/pro/img_ztkc.png" alt="">
                    <img class="phone-db" style="width:100%"  src="../assets/pro/img_ztkc.png" alt="">
                </div>
            </div>
        </div>
        <div class="customer">
            <div class="customer-content">
                <div class="img phone-dn" :class="coreAnimate9">
                    <img src="../assets/pro/img_gyshx.png" alt="">
                </div>
                <div class="text" :class="coreAnimate9">
                    <div>
                        <img src="../assets/pro/icon_sjkb.png" alt="">
                        <h3>供应商画像详情分析</h3>
                    </div>
                    <p>通过企业级标签化管理理念，实现了对企业供应商精细化、可视化、智能化等管理，最大程度降低供应商管理风险、物资质量风险、供货周期风险及物资价格风险等，提升企业供应商管理整体效率，有效防范管理与运营风险。</p>
                    
                    <img class="phone-db" style="width:100%" src="../assets/pro/img_gyshx.png" alt="">
                </div>
            </div>
        </div>

        <div class="black-warp">
            <div class="black-content">
                <div class="text"  :class="coreAnimate10">
                    <div>
                        <img src="../assets/pro/icon_sggn.png" alt="">
                        <h3>带有数据关联建议的物料<br/>申购功能</h3>
                    </div>
                    <p>通过智能仓储系统可以提供物料需求计划的基础数据。基于消耗的物料需求计划可以根据再订货原则生成采购建议，也可以根据消耗数据进行预测。这些数据为后期采购计划提供有利数据支撑。</p>
                    
                </div>
                <div class="img"  :class="coreAnimate10">
                    <img class="phone-dn" src="../assets/pro/img_sggn.png" alt="">
                    <img class="phone-db" style="width:100%"  src="../assets/pro/img_sggn.png" alt="">
                </div>
            </div>
        </div>
        <div class="customer">
            <div class="customer-content">
                <div class="img phone-dn" :class="coreAnimate11">
                    <img src="../assets/pro/img_sjkb.png" alt="">
                </div>
                <div class="text" :class="coreAnimate11">
                    <div>
                        <img src="../assets/pro/icon_cgjd.png" alt="">
                        <h3>采购进度一目了然</h3>
                    </div>
                    <p>采购员把每个订单状况更新在系统上，管理者无需与采购员沟通具体订单状况。通过系统，每个订单情况通过图表展示出来，所到环节一目了然。</p>
                    
                    <img class="phone-db" style="width:100%" src="../assets/pro/img_cgjd.png" alt="">
                </div>
            </div>
        </div>
        <div class="rfid">
            <div class="advantage">
                <div class="advantage-content">
                    <div class="commons-title"  :class="coreAnimate12">
                        智能云仓-<span>RFID</span> 技术特点解决方案
                    </div>
                    <div class="commons-title-label"  :class="coreAnimate12">
                        射频识别系统（RFID）非接触式自动识别技术以及自动化感应出入库门，是智能化库存盘点必不可少的技术
                    </div>
                </div>
            </div>
            <div class="black-warp" style="background:none">
                <div class="black-content">
                    <div class="text"  :class="coreAnimate13">
                        <div>
                            <img src="../assets/pro/icon_frid.png" alt="">
                            <h3>RFID射频识别方案</h3>
                        </div>
                        <p>移终端通过 RFID具有适用性、高效性、独一性； 实现库存管理的：绑定、质检、出库、入库、盘点等全流程操作；</p>
                        
                    </div>
                    <div class="img"  :class="coreAnimate13">
                        <img class="phone-dn" src="../assets/pro/img_frid.png" alt="">
                        <img class="phone-db" style="width:100%"  src="../assets/pro/img_frid.png" alt="">
                    </div>
                </div>
            </div>
            <div class="customer" style="background:none">
                <div class="customer-content">
                    <div class="img phone-dn" :class="coreAnimate14">
                        <img src="../assets/pro/img_zdhgy.png" alt="">
                    </div>
                    <div class="text" :class="coreAnimate14">
                        <div>
                            <img src="../assets/pro/icon_zdhgy.png" alt="">
                            <h3>自动化感应出入库门闸</h3>
                        </div>
                        <p>进出仓扫描区域，组合生产订单管理要求，实现无人执守式仓库管理</p>
                        
                        <img class="phone-db" style="width:100%" src="../assets/pro/img_zdhgy.png" alt="">
                    </div>
                </div>
            </div>


        </div>
        
	</div>
</template>

<script>
import Vue from 'vue'

export default {
	data() {
		return {
			programmeListType: 0,
			programmeList: [
				{ name: '客户管理', id: 0, icon: '&#xe6ac;' },
				{ name: '团队管理', id: 1, icon: '&#xe6aa;' },
				{ name: '订单管理', id: 2, icon: '&#xe6b0;' },
				{ name: '流程管理', id: 3, icon: '&#xe6b1;' },
				{ name: '生产管理', id: 4, icon: '&#xe6af;' },
				{ name: '仓储管理', id: 5, icon: '&#xe6ae;' },
			],
			show: false,
			animateClass: 'animate__animated animate__fadeInUp',
			advantageAnimate: 'dn',
			programmeAnimete: 'dn',
			coreAnimate1: 'dn',
            coreAnimate2: 'dn',
            coreAnimate3: 'dn',
            coreAnimate4: 'dn',
            coreAnimate5: 'dn',
            coreAnimate6: 'dn',
            coreAnimate7: 'dn',
            coreAnimate8: 'dn',
            coreAnimate9: 'dn',
            coreAnimate10: 'dn',
            coreAnimate11: 'dn',
            coreAnimate12: 'dn',
            coreAnimate13: 'dn',
            coreAnimate14: 'dn',
		}
	},
	methods: {
		handleScroll(e) {
            if(window.innerWidth > 800){
                if (document.documentElement.scrollTop > 200) {
                    this.advantageAnimate = this.animateClass
                }
                if (document.documentElement.scrollTop > 1300) {
                    this.programmeAnimete = this.animateClass
                }
                if (document.documentElement.scrollTop > 2100) {
                    this.coreAnimate1 = this.animateClass
                }
                if (document.documentElement.scrollTop > 2700) {
                    this.coreAnimate2 = this.animateClass
                }
                if (document.documentElement.scrollTop > 3200) {
                    this.coreAnimate3 = this.animateClass
                }
                if (document.documentElement.scrollTop > 3800) {
                    this.coreAnimate4 = this.animateClass
                }
                if (document.documentElement.scrollTop > 4500) {
                    this.coreAnimate5 = this.animateClass
                }
                if (document.documentElement.scrollTop > 5200) {
                    this.coreAnimate6 = this.animateClass
                }
                if (document.documentElement.scrollTop > 5800) {
                    this.coreAnimate7 = this.animateClass
                }
                
                if (document.documentElement.scrollTop > 6600) {
                    this.coreAnimate8 = this.animateClass
                }
                if (document.documentElement.scrollTop > 7200) {
                    this.coreAnimate9 = this.animateClass
                }
                if (document.documentElement.scrollTop > 7800) {
                    this.coreAnimate10 = this.animateClass
                }
                if (document.documentElement.scrollTop > 8400) {
                    this.coreAnimate11 = this.animateClass
                }
                if (document.documentElement.scrollTop > 8500) {
                    this.coreAnimate12 = this.animateClass
                }
                if (document.documentElement.scrollTop > 9000) {
                    this.coreAnimate13 = this.animateClass
                }
                if (document.documentElement.scrollTop > 9600) {
                    this.coreAnimate14 = this.animateClass
                }
            }else{
                if (document.documentElement.scrollTop > 200) {
                    this.advantageAnimate = this.animateClass
                }
                if (document.documentElement.scrollTop > 1300) {
                    this.programmeAnimete = this.animateClass
                }
                if (document.documentElement.scrollTop > 1800) {
                    this.coreAnimate1 = this.animateClass
                }
                if (document.documentElement.scrollTop > 2500) {
                    this.coreAnimate2 = this.animateClass
                }
                if (document.documentElement.scrollTop > 3000) {
                    this.coreAnimate3 = this.animateClass
                }
                if (document.documentElement.scrollTop > 3500) {
                    this.coreAnimate4 = this.animateClass
                }
                if (document.documentElement.scrollTop > 4000) {
                    this.coreAnimate5 = this.animateClass
                }
                if (document.documentElement.scrollTop > 4500) {
                    this.coreAnimate6 = this.animateClass
                }
                if (document.documentElement.scrollTop > 5000) {
                    this.coreAnimate7 = this.animateClass
                }

                if (document.documentElement.scrollTop > 5600) {
                    this.coreAnimate8 = this.animateClass
                }
                if (document.documentElement.scrollTop > 6100) {
                    this.coreAnimate9 = this.animateClass
                }
                if (document.documentElement.scrollTop > 6600) {
                    this.coreAnimate10 = this.animateClass
                }
                if (document.documentElement.scrollTop > 7100) {
                    this.coreAnimate11 = this.animateClass
                }
                if (document.documentElement.scrollTop > 7500) {
                    this.coreAnimate12 = this.animateClass
                }
                if (document.documentElement.scrollTop > 8000) {
                    this.coreAnimate13 = this.animateClass
                }
                if (document.documentElement.scrollTop > 8600) {
                    this.coreAnimate14 = this.animateClass
                }
            }
			
		},
	},
	created() {
		const v = this
		window.addEventListener('scroll', this.handleScroll)
		
	},
}
</script>
<style>
.swiper-pagination-bullet {
	background: #fff;
	width: 20px;
	height: 4px;
	border-radius: 0px;
	opacity: 1;
}
.swiper-pagination-bullet-active {
	background: #487cff;
}
</style>
<style lang='less' scoped>
.erp {
    .frid-box{
        background: #3678fc;
        height: 120px;
        .frid-box-bg{
            height:120px;
            background: url(../assets/pro/img_tl.png) no-repeat;
            background-size: auto 120px;
            background-position: center center;
        }
    }
    .rfid{
        background: url(../assets/pro/img_bjht.png) no-repeat;
        background-size: cover;
        padding-top: 120px;
    }
    .vs{
        background: url(../assets/erp/img_vs.png) no-repeat;
        background-size: cover;
        position: relative;
        .vs-img{
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -60px 0 0 -60px;
        }
        .vs-content{
            display: flex;
            margin: 0 auto;
            width: 1200px;
            .left-box{
                width: 50%;
                height: 500px;
                margin-top:20px;
                padding: 0 60px;
                .text-2{
                    color: #fff;
                    transform: skew(-15deg);
                    div{
                        transform: skew(15deg);
                    }
                }
                li::before{
                    content:"";
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-radius: 5px;
                    background: #fff;
                    position: absolute;
                    top:21px;
                    left: 0;
                    
                }
            }
            .right-box{
                width: 50%;
                height: 500px;
                margin-top:20px;
                padding: 0 60px;
                .text-1{
                    color: #fff;
                    padding-left: 80px;
                    position: relative;
                    i{
                        color:#fff;
                    }
                }
                .text-1::before{
                    content:"";
                    display: block;
                    width: 50px;
                    height: 58px;
                    background: url(../assets/erp/icon_xx.png);
                    position: absolute;
                    left: 0;
                    top:-6px;
                }
                .text-2{
                    background: #fff;
                    color: #487CFF;
                    transform: skew(-15deg);
                    div{
                        transform: skew(15deg);
                    }
                }
                li{
                    padding-left: 0px;
                    i{
                        color:#fed92e;
                        margin-right:25px;
                        font-size: 20px;
                    }
                }
            }
            .text-1{
                font-size: 30px;
                margin-top: 60px;
                color: #487CFF;
                font-weight: bold;
                padding-left: 40px;
                i{
                    font-size: 40px;
                    float: right;
                    color: #487CFF;
                }
            }
            .text-2{
                height: 60px;
                line-height: 60px;
                margin-top: 50px;
                background: #487CFF;
                margin-bottom: 30px;
                text-align: center;
                font-weight: bold;
                font-size: 20px;
            }
            ul{
                
                li{
                    list-style: none;
                    font-size: 18px;
                    color: #fff;
                    line-height: 56px;
                    padding-left: 40px;
                    position: relative;
                }
            }
        }
        
    }
    .black-warp{
        background: #f1f1f1;
        .black-content{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            height: 590px;
            justify-content: space-between;
            padding: 90px 0 100px;
            .img{}
            .text{
                width: 480px;
                padding-left: 90px;
                padding-top: 74px;
                h3{
                    color: #333;
                    font-size: 32px;
                    margin: 15px 0;
                }
                p{
                    color: #666;
                    font-size: 16px;
                    line-height: 32px;
                }
            }
        }
    }
    .customer{
        background: #fff;
        .customer-content{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            height: 590px;
            justify-content: space-between;
            padding: 90px 0 100px;
            .img{}
            .text{
                width: 480px;
                padding-right: 90px;
                padding-top: 74px;
                h3{
                    color: #333;
                    font-size: 32px;
                    margin: 15px 0;
                }
                p{
                    color: #666;
                    font-size: 16px;
                    line-height: 32px;
                }
            }
        }
    }
	.zhendui{
        background: url(../assets/pro/img_ctcc.png) no-repeat;
        background-size: cover;
        height: 1024px;
        position: relative;
        .zhendui-logo{
            position: absolute;
            top: -70px;
            left:50%;
            margin-left: -70px;
        }
        .zhendui-content{
            padding-top:167px;
            width: 1200px;
            margin: 0 auto;
        }
    }

	.painPoint {
		height: 960px;
		width: 1200px;
		margin: 0 auto;
		.content {
			height: 582px;
			background: #fff;
			background-size: cover;
			margin-top: 120px;
			display: flex;
			justify-content: space-between;
			padding: 20px 40px 0;
			.left-card-warp {
				width: 280px;
				.left-card-box {
					height: 60px;
					margin-bottom: 20px;
					position: relative;
					text-align: center;
					line-height: 60px;
					background: #3564f5;
					border-radius: 6px;
					color: #fff;
					font-size: 16px;
				}
				.left-card-box::before {
					content: '';
					display: block;
					width: 16px;
					height: 16px;
					border: 3px solid #fdb622;
					border-radius: 50%;
					position: absolute;
					top: 22px;
					left: 56px;
				}
			}
			.right-card-warp {
				width: 280px;
				.right-card-box {
					padding: 10px 20px;
					background: #3564f5;
					border-radius: 6px;
					color: #fff;
					font-size: 16px;
					margin-bottom: 24px;
					.label {
						padding-left: 26px;
						position: relative;
					}
					.label::before {
						position: absolute;
						content: '';
						display: block;
						width: 16px;
						height: 16px;
						border: 3px solid #ff6476;
						border-radius: 50%;
						position: absolute;
						top: 3px;
						left: 0px;
					}
					.text {
						font-size: 12px;
						margin-top: 10px;
						line-height: 18px;
					}
				}
			}
		}
	}
	.swiper {
		width: 100%;
		height: 100vh;
		min-height: 840px;
		.swiper-slide {
			overflow: hidden;
			background: #00072d;
			height: 100vh;
			min-height: 840px;
		}
		.banner-btn {
			margin: 0 auto;
			width: 180px;
			height: 60px;
			background: #487cff;
			color: #fff;
			text-align: center;
			line-height: 60px;
			margin-block: 70px;
			cursor: pointer;
		}
		.banner-btn:active {
			background: #fff;
			color: #487cff;
		}
		.content-box {
			background: url(../assets/pro/img_banner_bj.jpg) no-repeat;
			height: 100vh;
			min-height: 840px;
			background-size: cover;
			margin: auto;
			div {
				padding-top: 240px;
			}
			img {
				display: block;
				margin: 0 auto;
			}
		}
	}
    @media only screen and (max-width: 800px) {
		.black-warp {
			background: #f1f1f1;
			.black-content {
				width: 100%;
				margin: 0 auto;
				display: block;
				height: auto;
				justify-content: space-between;
				padding: 50px 12px;
				.img {
				}
				.text {
					width: 100%;
					padding: 0;
					div {
						display: flex;
						text-align: center;
						justify-content: center;
						align-items: center;
						img {
							width: 25px;
							height: 25px;
							margin-right: 8px;
						}
					}
					h3 {
						color: #333;
						font-size: 20px;
						margin: 15px 0;
					}
					p {
						color: #666;
						font-size: 16px;
						line-height: 32px;
					}
					.phone-db {
						width: 100%;
						margin-top: 20px;
					}
				}
			}
		}
		.customer {
			background: #fff;
			.customer-content {
				width: 100%;
				margin: 0 auto;
				display: block;
				height: auto;
				justify-content: space-between;
				padding: 50px 12px;

				.img {
				}
				.text {
					width: 100%;
					padding-right: 0;
					padding-top: 0px;
					div {
						display: flex;
						text-align: center;
						justify-content: center;
						align-items: center;
						img {
							width: 25px;
							height: 25px;
							margin-right: 8px;
						}
					}
					h3 {
						color: #333;
						font-size: 20px;
						margin: 15px 0;
					}
					p {
						color: #666;
						font-size: 16px;
						line-height: 32px;
					}
					.phone-db {
						width: 100%;
						margin-top: 20px;
					}
				}
			}
		}
		.zhendui {
			background: url(../assets/h5-erp/img_bl.png) no-repeat;
			background-size: cover;
            padding-bottom: 40px;
            height: auto;
			.zhendui-logo{
                width: 70px;
                height: 70px;
                position: absolute;
                top: -35px;
                left:50%;
                margin-left: -35px;
                img{
                    width: 100%;
                }
            }
			.zhendui-content {
				padding-top: 50px;
				width: 100%;
				margin: 0 auto;
			}
		}
		.swiper-slide {
			background: #fff !important;
		}
		.swiper {
			.content-box {
				background: url(../assets/h5-pro/banner.png) no-repeat;
				background-size: cover;
    			background-position: center center;
				img {
					width: 90%;
					margin: 0 5%;
				}
                div{
                    padding-top:0;
                }
			}
		}
		.trial {
			height: 100px;
		}
		.case {
			.case-content {
				width: 100%;
			}
		}
		.core {
			height: auto;
			.core-content {
				width: 100%;
				img {
					width: 90%;
				}
			}
		}
		.painPoint {
			width: 100%;
			height: auto;
		}
		.advantage {
			.advantage-content {
				width: 100%;
				padding: 35px 24px;
				ul {
					li {
						width: 100%;
						display: flex;
						.img-box {
							width: 60px;
							height: 60px;
						}
						.line {
							width: 1px;
							height: 62px;
						}
					}
				}
			}
		}
	}
}
</style>